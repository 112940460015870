/* DUE TO CHANGE OF LAYOUT THIS IS A NEW CORE CSS FILE 06/2023 */
@import url("https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@300;400;500;600;700;800;900&display=swap");

body {
  font-family: "Red Hat Display", sans-serif;
  overflow-y: auto;
  overflow-x: hidden !important;
}

::-webkit-scrollbar {
  width: 0; /* Ukrywa suwak w przeglądarkach opartych na WebKit */
}

::-webkit-scrollbar-thumb {
  display: none; /* Ukrywa "kciuk" suwaka */
}

body {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}

body a {
  text-decoration: none;
}

/* HEADER */
header {
  background: #45cc24;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);
}

#header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

#homeMobileCategories .carousel-wrapper {
  display: flex;
  overflow-x: auto;
}

#homeMobileCategories .carousel-wrapper .categoriesCarousel {
  display: flex;
  gap: 20px;
}

.menuDeskop {
  display: flex;
  flex-direction: row;
  gap: 40px;
  align-items: center;
}

.menuMobile {
  display: none;
}

.menuMobile {
  font-size: 32px;
}

.django-ckeditor-widget {
  width: 100%;
}

.ck.ck-content {
  min-height: 200px;
}

.menuDeskop a {
  color: #00334a;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
}

#wiadomosciCounter.display {
  background: #ca3a38;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  font-size: 12px;
  line-height: 20px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.addOfferButton,
.addOfferButton:hover {
  padding: 16px 32px;
  background: #ffffff;
  box-shadow: 0px 0px 9px 3px rgba(0, 0, 0, 0.15);
  border-radius: 60px;
  color: #00334a;
  font-weight: 700;
  font-size: 18px;
}

.myAccountToggle {
  background: none;
  border: none;
  color: #00334a;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
}

.menuDeskop a.hover:hover {
  color: #fff;
}

.offerCatImageWrap {
  padding: 10px;
}

.offerCatImageWrap:hover,
.categoryBox.active div {
  background: #053954;
  border-radius: 10px;
}

.offerCatImageWrap:hover img,
.categoryBox.active div img {
  opacity: 1 !important;
  filter: invert(0%) hue-rotate(10deg) brightness(10) contrast(10);
}

.myAccountToggle:hover {
  color: #fff;
}

.myAccountToggle::after {
  display: none;
}

.dropDownMenu {
  position: relative;
}

.dropDownMenu:hover .dropDownItemsMenu {
  display: flex;
}

.dropDownItemsMenu a:hover {
  text-decoration: underline !important;
}

.removeIMAGELINK {
  position: absolute;
  top: -20px;
  right: 20px;
  font-size: 20px;
  color: red;
  cursor: pointer;
}

.galleryImages .wrapper {
  position: relative;
}

#addOfferThankYou {
  min-height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.thanksWrap {
  border-radius: 16px;
  border: 1px solid var(--green, #45cc24);
  background: #fff;
  position: relative;

  /* BOX shadow */
  box-shadow: 0px 0px 32px 0px rgba(0, 0, 0, 0.1);
}

.thanksWrap .closeThankYou {
  position: absolute;
  top: 10px;
  right: 10px;
}

.thanksWrap .problems a {
  color: #45cc24;
}

.studioContainer {
  min-height: calc(65vh);
}

.studioContainer h1 {
  color: #0e2231;
  font-size: 3rem;
  margin-bottom: 2rem;
}

.studioContainer a {
  color: #45cc24;
}

.thanksWrap .actionButtons {
  margin: 20px 0;
}

.thanksWrap .problems {
  color: var(--gray, #797979);
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 14.4px */
}

.dropDownItemsMenu {
  position: absolute;
  top: 10px !important;
  left: -60px !important;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  padding: 15px 40px;
  min-width: 250px;
  max-width: 100%;
  display: none;
  flex-direction: column;
  gap: 15px;
  z-index: 9999;
}

.categories .notActive .imgWrapCat {
  opacity: 0.4;
}

#category #subCategory .imgWrapCat {
  height: 80px;
  width: 80px;
  background: #135d7e;
}

#category #finalCategory .imgWrapCat {
  background: #477a90;
  width: 70px;
  height: 70px;
}

#category #finalCategory .imgWrapCat:hover img {
  filter: invert(0%) hue-rotate(10deg) brightness(10) contrast(10);
}

.dropDownItemsMenu li {
  margin: 10px 0;
}

.dropDownItemsMenu a {
  margin: 10px 0 !important;
}

/* FOOTER */

footer {
  background: #00334a;
  color: white;
}

footer a {
  color: white;
  font-weight: 400;
  font-size: 16px;
  margin-top: 4px;
}

footer a:hover {
  color: white;
}

footer a.green,
footer a.green:hover {
  color: #45cc24;
}

.footerColumn {
  display: flex;
  flex-direction: column;
}

#footerLogo {
  display: flex;
  align-items: end;
  justify-content: flex-end;
}

.studioContainer .contactForm {
  margin: auto;
  max-width: 800px;
}

.studioContainer p {
  font-size: 18px;
  color: var(--navy, #00334a);
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 12px */
}

.studioContainer .contactForm p {
  margin-bottom: 5px;
  font-size: 15px;
  color: var(--navy, #00334a);
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 12px */
}

#footerLogo img {
  max-width: 100px;
}

/* HOME PAGE */
#homeHero h1 {
  color: #00334a;
  font-weight: 900;
  font-size: 60px;
  line-height: 120%;
}

#homeHero h2 {
  color: #00334a;
  font-weight: 600;
  font-size: 26px;
  line-height: 120%;
}

#homeHero h2 a {
  color: #00334a;
  font-weight: 700;
  text-decoration: underline;
}

.topSearch {
  width: 100%;
  background: #ffffff;
  border: 2px solid #45cc24;
  box-shadow: inset 4px -4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 102px;
  height: 70px;
  display: flex;
  flex-direction: row;
  padding: 5px;
}

.topSearch .searchButton {
  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.1) 100%
    ),
    #45cc24;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
  border-radius: 60px;
  color: #ffffff;
  font-size: 24px;
  font-weight: 700;
  width: 130px;
  border: none;
}

.topSearch .searchButton:hover {
  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.1) 100%
    ),
    #40be22;
}

.headerSearch .searchButton:hover {
  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.1) 100%
    ),
    #40be22;
}

.topSearch input {
  border: none;
  background: transparent;
  font-size: 22px;
  font-weight: 400;
  padding-left: 20px;
}

input:focus {
  outline: none;
}

.topSearch input:focus {
  outline: none;
}

.topSearch .contentInput {
  width: 70%;
}

.topSearch .cityInput {
  border-left: 2px solid #45cc24;
  width: calc(30% - 130px);
}

#category .category img {
  opacity: 0.5;
}

#category .category img#active {
  opacity: 1;
}

.categoriesRow {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow-x: auto;
}

.categoriesRow .category {
  padding: 0 10px;
  text-align: center;
  flex-basis: 10%;
  flex-grow: 0;
  flex-shrink: 0;
}

.categoriesRow .category p {
  color: #1e1e1e;
}

.offersGrid {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 20px;
  padding-bottom: 20px;
}

.offersGrid .offerWrapper {
  flex-shrink: 0;
  flex-grow: 0;
}

.offer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: white;
  padding: 12px;
  box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  height: 100%;
}

.offer img {
  height: 150px;
  object-fit: cover;
  border-radius: 8px;
}

.carousel-indicators button {
  width: 10px !important;
  height: 10px !important;
  border-radius: 50% !important;
  color: white;
}

.carousel-indicators {
  margin-bottom: 0.3rem !important;
}

.offerTitle {
  font-weight: 700;
  height: 44px;
  overflow: hidden;
  font-size: 15px;
  color: #00334a;
  margin-top: 5px;
  margin-bottom: 5px;
  max-height: 44px;

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}

.offerTitle.detailOffer {
  height: unset;
  max-height: unset;
  -webkit-box-orient: unset;
}

.titleCompletion {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: #6b6b6b;
}

.offerAtributes {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.offerAtributes p {
  font-weight: 400;
  font-size: 9px;
  line-height: 120%;
  color: #00334a;
}

.offer .leftSide {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding-left: 0;
  padding-right: 0;
}

.offer .leftSide .offer_price {
  width: 100%;
  background: #45cc24;
  font-weight: 700;
  height: fit-content;
  font-size: 16px;
  line-height: 21px;
  color: #00334a;
  display: flex;
  align-items: center;
  justify-content: center;
}

.offer .leftSide .price_for {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 11px;
  line-height: 21px;
  color: #00334a;
}

.bottomOffer .row {
  padding-left: 0.5rem;
  align-items: center;
}

.offer .rightSide {
  text-align: right;
  font-size: 12px;
}

/* CATEGORY */
.searchTop {
  background: #00334a;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.35),
    inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.headerSearch {
  width: 100%;
  background: #24637f;
  border-radius: 50px;
  height: 50px;
  display: flex;
  flex-direction: row;
  padding: 5px;
}

.headerSearch .searchButton {
  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.1) 100%
    ),
    #45cc24;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
  border-radius: 26px;
  color: #ffffff;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  padding: 0 30px;
  border: none;
}

.headerSearch input {
  border: none;
  background: transparent;
  font-size: 22px;
  font-weight: 400;
  padding-left: 20px;
  color: white;
}

.headerSearch i {
  color: white;
}

.error {
  color: #ca3a38;
}

.headerSearch .contentInput {
  width: calc(73% - 110px);
}

.headerSearch .cityInput {
  border-left: 2px solid white;
  width: 15%;
}

.headerSearch .distanceGroup {
  border-left: 2px solid white;
  width: 14%;
  display: flex;
  align-items: center;
}

.categoryBox {
  max-width: 90px;
  word-break: auto;
}

.headerSearch .dropdown,
.headerSearch .dropdown .myInput {
  border: none;
}

.headerSearch .myInput .headerSearch .myInput input {
  width: 90px;
}

.headerSearch .myInput {
  display: flex;
  height: 100%;
  flex-direction: row;
  align-items: center;
}

.headerSearch .myInput input {
  width: 90px;
}
#violationBody p {
  text-align: justify;
}

#violationBody textarea {
  border: 1px solid #ca3a38;
  border-radius: 16px;
}

#violationModal .modal-footer {
  border-top: none;
}

#violationModal .modal-footer button {
  box-shadow: 0px 4px 4px 0px #0000001a;
  background: #ca3a38;
  color: white;
  padding: 8px 28px 8px 28px;
  border: none;
  border-radius: 80px;
}

.table #violation td {
  max-height: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
}
.categoriesBody td {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
}

.dropdown-menu.show {
  z-index: 9999;
}
.violation p {
  color: #797979;
  font-weight: 900;
  font-size: 12px;
}
.violation p:hover span {
  text-decoration: underline;
}
.headerSearch .myInput i {
  font-size: 25px;
}

.headerSearch ::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: white;
  opacity: 1; /* Firefox */
}

.headerSearch :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: white;
}

.headerSearch ::-ms-input-placeholder {
  /* Microsoft Edge */
  color: white;
}

#offers h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 120%;
}

.pagination {
  justify-content: center;
}

.pagination .page-link {
  color: black;
}

/* Search results */
.filters {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
}

.filters .filter {
  border: 1px solid #f5f5f5;
  border-radius: 20px;
  padding: 3px 8px;
  color: white;
}

.filters .filter#active {
  background: white;
}

.filters .filter#active button {
  color: #00334a;
}

.filters .filter.clear {
  cursor: pointer;
  background: #f5f5f5;
  color: #00334a;
  font-weight: 400;
  font-size: 16px;
}

.filters .filter button {
  border: none;
  background: transparent;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  color: #f5f5f5;
}

.dropdown-toggle::after {
  vertical-align: middle;
  font-size: 20px;
}

.offerWrapper .carousel-indicators {
  overflow: hidden;
}

.carousel-indicators [data-bs-target] {
  background: transparent;
  border: 1px solid white;
  height: 10px !important;
  width: 10px !important;
  opacity: 1 !important;
  flex-shrink: 0;
}

.carousel-indicators .active {
  background: white !important;
}

.carousel-control-next-icon {
  background-image: url("/public/images/svg/right.svg");
}

.carousel-control-prev-icon {
  background-image: url("/public/images/svg/right.svg");
  transform: rotate(180deg);
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  height: 4.5rem;
}

.carousel-control-next,
.carousel-control-prev {
  opacity: 1;
}

.categoriesRowHome .imgWrapCat {
  background: #053954;
  width: 70px;
  padding: 10px;
  border-radius: 50%;
  margin: auto;
  height: 70px;
}

#categoriesGird p {
  font-size: 14px;
}

#homeMobileCategories .imgWrapCat {
  background: #053954;
  width: 70px;
  padding: 5px;
  border-radius: 50%;
  margin: auto;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#homeMobileCategories p {
  font-size: 0.8rem;
}

#homeMobileCategories .imgWrapCat img {
  width: 50px;
  height: 50px;
  min-width: 50px;
}

.categoriesRowHome .imgWrapCat img {
  padding: 10px;
}

.categoriesRowHome .imgWrapCat:hover img {
  filter: invert(0%) hue-rotate(10deg) brightness(10) contrast(10);
}

#category .imgWrapCat {
  background: #053954;
  padding: 10px;
  border-radius: 50%;
  aspect-ratio: 1/1;
}

#category .imgWrapCat img {
  padding: 10px;
}

#category .imgWrapCat:hover img {
  filter: invert(0%) hue-rotate(10deg) brightness(10) contrast(10);
}

#subCategory .categoryBox,
#finalCategory .categoryBox {
  max-width: 100px;
}

#category .notActive #subCategory .categoriesCarousel img {
  width: 70px;
  min-width: 60px;
  padding: 10px;
}

#finalCategory .categoryBox img {
  width: 60px;
  min-width: 50px;
  padding: 10px;
}

#subCategory .categoryBox p {
  font-size: 0.8rem;
}

#finalCategory .categoryBox p {
  font-size: 0.7rem;
}
.modal-backdrop {
  z-index: 4000 !important;
}
.modal {
  z-index: 4001;
}
.alertImage {
  cursor: pointer;
  max-height: 40px;
}

#alertModal .closeModalOffers {
  position: absolute;
  cursor: pointer;
  top: 20px;
  right: 20px;
  color: #33363f;
  font-size: 24px;
  border: 2px solid #33363f;
  border-radius: 50%;
}

#alertModal h5 {
  font-size: 26px;
  font-weight: 700;
  line-height: 31.2px;
  text-align: center;
  color: #1e1e1e;
}

#alertModal .text {
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  text-align: left;
}

#alertModal button.add {
  background: #45cc24;
  border: 1px solid #45cc24;
  box-shadow: 0px 4px 4px 0px #0000001a;
  padding: 8px 28px 8px 28px;
  border-radius: 80px;
  color: white;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  width: fit-content;
}

#alertModal button.edit {
  border: 1px solid #45cc24;
  border-radius: 80px;
  box-shadow: 0px 4px 4px 0px #0000001a;
  padding: 8px 28px 8px 28px;
  background: transparent;
  width: fit-content;
  color: #45cc24;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
}

.alertModalButtons {
  margin-top: 20px;
  align-items: center;
}

.countOffers {
  font-size: 18px;
  font-weight: 400;
  line-height: 20px;
}

/* OFFER DETAIL */

#offerDetail .section {
  box-shadow: 0px 0px 32px 0px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  padding: 12px;
}

#offerDetail .imagesSlider.section,
#offerDetail .advertisementStudio.section {
  padding: 0;
  text-align: center;
}

.subImages {
  display: flex;
  flex-direction: row;
  gap: 8px;
  flex-wrap: wrap;
  align-items: center;
}

.subImages img {
  width: 44px;
  height: 44px;
  object-fit: cover;
  border: 2px solid #1e1e1e;
}

.moreImagesSlider {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.moreImagesSlider img {
  cursor: pointer;
}

#offerDetail .underline {
  opacity: 1;
  color: #00334a;
}

.sellerSection h3 {
  color: #000;
  font-size: 15px;
  font-weight: 700;
  line-height: 120%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sellerInfo {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.sellerInfo span,
.sellerInfo span a,
.sellerInfo .text-end {
  color: #000;
  font-size: 11px;
  line-height: 120%;
}

.sellerInfo .font-16 {
  font-size: 16px;
}

.sellerInfo .price {
  background: #45cc24;
  padding: 3px 6px;
  color: #00334a;
  font-size: 23px;
  font-weight: 700;
  line-height: 120%;
}

.sellerSection button {
  width: 100%;
  font-size: 16px;
  line-height: 120%;
  padding: 8px 28px;
  border-radius: 80px;
  cursor: pointer;
}

.sellerSection button.wishlistButton,
button.wishlistButton {
  border: 1px solid #ca3a38;
  background: #ca3a38;
  color: white;
  margin-top: 20px;
  padding: 8px 0;
}
.mapPreview {
  width: 100%;
  height: auto;
  cursor: pointer;
}

#wishlistSection button.wishlistButton {
  border-radius: 80px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}

.sellerSection .call {
  margin: 20px 0 15px;
  border: 1px solid #00334a;
  background: #fff;
  color: #00334a;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
}

.sellerSection .call.disabled {
  opacity: 0.5;
}

.sellerSection .sendMessButton,
.sendMessModal {
  color: #fff;
  border: 1px solid #00334a;
  background: #00334a;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
}

.sendMessModal {
  font-size: 16px;
  line-height: 120%;
  padding: 8px 28px;
  border-radius: 0.375rem;
  cursor: pointer;
}

.circleImage {
  object-fit: cover;
  max-width: 50px;
  aspect-ratio: 1/1;
  border-radius: 50%;
}

.misjaTop {
  background: #ffc9ba;
}

.misjaTop h1 {
  font-size: 42px;
  font-style: italic;
  font-weight: 900;
  line-height: 50px;
  letter-spacing: 0;
  text-align: left;
  color: #000000;
}

.misjaTop p,
.misjaMiddle p,
.bottomMisja p {
  font-size: 26px;
  font-weight: 500;
  line-height: 34px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #000000;
}

.misjaMiddle h2 {
  font-size: 42px;
  font-style: italic;
  font-weight: 900;
  line-height: 50px;
  letter-spacing: 0em;
  text-align: left;
  color: black;
}

.bottomMisja {
  background: #45cc24;
  margin-bottom: -1.5rem;
}

.bottomMisja h3 {
  font-size: 42px;
  font-style: italic;
  font-weight: 900;
  line-height: 50px;
  letter-spacing: 0em;
  text-align: left;
  color: black;
}

.bottomMisja h4 {
  text-align: left;
  color: #ffffff;
  font-size: 42px;
  font-style: italic;
  font-weight: 900;
  line-height: 50px;
  margin-top: 3rem;
}

.carousel-container .ui.card.carouselItemProduct {
  margin-right: 10px;
  flex: 0 0 200px;
  height: 300px;
  overflow: hidden;
}

.carousel-container .ui.card.carouselItemProduct .content .header {
  max-height: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
}

#category .categoriesCarousel {
  align-items: flex-start;
}

.conditionalWhite .categoryBox.NoOffers .imgWrapCat {
  opacity: 0.7;
}

.conditionalWhite .categoryBox.NoOffers .imgWrapCat img {
  filter: invert(0%) hue-rotate(10deg) brightness(10) contrast(10);
}

.not-visible {
  display: none;
}

@media screen and (min-width: 992px) {
  .bottomMisja {
    margin-bottom: -3rem;
  }
}

@media screen and (max-width: 992px) {
  .misjaTop h1,
  .bottomMisja h3,
  .misjaMiddle h2,
  .bottomMisja h4 {
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 2.5rem;
  }

  .misjaTop p,
  .misjaMiddle p,
  .bottomMisja p {
    font-size: 26px;
    line-height: 34px;
  }
}

@media screen and (min-width: 1199px) {
  .misjaTop h1,
  .bottomMisja h3,
  .misjaMiddle h2 {
    margin-bottom: 20px;
  }
}

#searchResult {
  min-height: calc(100vh - 431px - 3rem);
}

#categoriesGird .active img {
  opacity: 1;
}

#categoriesGird .notActive img {
  opacity: 0.4;
}

#categoriesGird p {
  color: black;
}

#offerDetail .topBox h5 {
  color: #000;
  font-size: 16px;
  font-weight: 700;
  line-height: 120%;
}

#offerDetail .assistentBlock {
  background: #ffc9ba;
}

#offerDetail .assistentBlock .desc {
  color: #000;
  font-size: 14px;
  line-height: 140%;
}

.modal-content .form-control:focus {
  outline: none;
  box-shadow: none;
  border-color: #ced4da;
}

#violationModal {
  border-radius: 16px;
}
#violationModal h5 {
  text-align: center;
  font-size: 26px;
  font-weight: 700;
  line-height: 31.2px;
  text-align: center;
  text-decoration-skip-ink: none;
  text-align: center;
  width: 100%;
  padding: 20px 0 0;
}

#violationModal form {
  position: relative;
}
#violationModal .modal-header {
  border-bottom: none;
}
#violationModal .modal-header button {
  position: absolute;
  top: 20px;
  right: 20px;
}
#offerDetail .callArchitect {
  border: none;
  border-radius: 80px;
  color: #fff;
  font-size: 14px;
  line-height: 120%;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding: 8px 10px;
  align-items: center;
  background: #00334a;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
}

#offerDetail .learnMore {
  margin-top: 16px;
  margin-bottom: 0;
  text-decoration-line: underline;
}

#offerDetail h1.offerTitle {
  color: #00334a;
  /* XL */
  font-size: 42px;
  font-weight: 900;
  line-height: 120%;
}

#offerDetail .carousel a.infinite-item {
  flex-shrink: 0;
  width: 216px;
}
#offerDetail .carousel a.infinite-item .carousel {
  padding: 0;
}
#offerDetail .offersDetail .carousel {
  display: flex;
  gap: 20px;
  padding-bottom: 20px;
}

.offersDetail h3 {
  font-size: 26px;
  font-weight: 700;
  line-height: 31.2px;
  text-align: left;
  color: #000000;
}

#offerDetail .breadcrumb {
  color: rgba(0, 0, 0, 0.5);
  font-size: 16px;
  line-height: 120%;
}

#offerDetail .breadcrumb a {
  color: rgba(0, 0, 0, 0.5);
}

#offerDetail .descriptionOffer {
  color: #000;
  white-space: pre-line;
  font-size: 16px;
  line-height: 120%;
}

#offerDetail .descriptionOffer h2 {
  font-size: 18px;
  font-weight: 700;
}

.counterwords {
  display: flex;
  flex-direction: row;
}

.counterwords.red,
.red {
  color: red;
}

#descriptionContent,
#offerDetail .descriptionOffer {
  word-break: auto-phrase;
}

#offerDetail .descriptionOffer h3 {
  font-size: 17px;
  font-weight: 700;
}

#offerDetail .descriptionOffer h4 {
  font-size: 16px;
  font-weight: 700;
}

#offerDetail .underlineDesc {
  opacity: 1;
  color: rgba(0, 0, 0, 0.5);
}

.offerBottom span {
  text-decoration: underline;
  cursor: pointer;
}

#offerDetail .learnMore {
  cursor: pointer;
}

#offerDetail .imagesSlider {
  position: relative;
  margin: auto;
}

#offerDetail .offerSlide {
  height: 70vh;
}
#offerDetail .offerSlide.hidden {
  display: none;
}
#offerDetail .offerSlide.visible {
  display: block;
}
#offerDetail .offerSlide img {
  height: 100% !important;
  object-fit: contain;
}

#offerDetail .prev,
#offerDetail .next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  margin-top: -22px;
  color: black;
  font-size: 18px;
  transition: 0.6s ease;
}

.bottomOffer .rightSide {
  color: #00334a;
  display: flex;
  align-items: end;
  justify-content: end;
}

#offerDetail .prev:hover,
#offerDetail .next:hover {
  color: rgba(0, 0, 0, 0.4);
}

.chatOption.hide {
  display: none;
}

button.remove {
  padding: 16px 32px;
  background: #b22121;
  box-shadow: 0px 0px 9px 3px rgba(0, 0, 0, 0.15);
  border-radius: 60px;
  color: white;
  font-weight: 700;
  font-size: 18px;
}

#panelSettingsLabel p.active {
  font-weight: 700;
}

p.message {
  font-size: 18px;
}

p.message.error {
  color: #ca3a38;
}

.imagesPreviewChat {
  position: absolute;
  top: -40px;
  left: 10px;
  max-height: 40px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
}

.imagesPreviewChat img {
  height: 30px;
  width: auto;
}

form.sendMess {
  position: relative;
}

p.message.success {
  color: #45cc24;
}

#offerDetail .next {
  right: 0;
}

#offerDetail .prev {
  left: 0;
}

#offerDetail .prev::after {
  border-style: solid;
  border-width: 0.1em 0.1em 0 0;
  content: "";
  left: 2rem;
  display: inline-block;
  height: 2.5em;
  position: relative;
  top: 0.15em;
  transform: rotate(-135deg);
  vertical-align: top;
  width: 2.5em;
}

#offerDetail .next::after {
  border-style: solid;
  border-width: 0.1em 0.1em 0 0;
  content: "";
  right: 2rem;
  display: inline-block;
  height: 2.5em;
  position: relative;
  top: 0.15em;
  transform: rotate(45deg);
  vertical-align: top;
  width: 2.5em;
}

#offerDetail .slider_hidden {
  opacity: 0.5;
}

#offerDetail .slider_visible {
  opacity: 1;
}

.OfferDetailModal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 99999; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.9); /* Black w/ opacity */
}

.OfferDetailModal .modal-content {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
  object-fit: contain;
  max-height: 80vh;
  position: relative;
}

#offerDetail .OfferDetailModal .next::after {
  color: white;
}

#offerDetail .OfferDetailModal .prev::after {
  color: white;
}

#offerDetail .OfferDetailModal .next {
  right: 5%;
}

#offerDetail .OfferDetailModal .prev {
  left: 5%;
}

.OfferDetailModal .close {
  position: absolute;
  top: 15px;
  right: 35px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}

.OfferDetailModal .close:hover,
.OfferDetailModal .close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}

#homeMobileCategories {
  display: none !important;
}

.only_mobile {
  display: none;
}

.headerLogo {
  max-height: 50px;
}

.topMobileMenu {
  color: var(--navy, #00334a);
  text-align: right;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-right: 20px;
}

#menuDropdown {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #00334a;
}

#menuDropdown .dropdown-item {
  color: var(--navy, #00334a);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

#menuDropdown ul {
  border-radius: 15px;
  background: #fff;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.25);
}

.dropdown-menu p.accent {
  color: var(--navy, #00334a);
  font-size: 16px;
  margin-top: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.filters .inputDouble {
  display: flex;
  flex-direction: row;
  gap: 5px;
}
.cPointer {
  cursor: pointer;
}

.filters .inputDouble input {
  max-width: 85px;
  border: none;
  border-bottom: 1px solid #1e1e1e;
  color: var(--gray, #797979);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 19.2px */
}

.noOffers {
  min-height: 30vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.noOffers h1 {
  width: 100%;
}

.filters .dropdown-item {
  color: rgba(0, 51, 74, 1);
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 700;
  line-height: 21px;
  gap: 20px;
}

.filters .selectItem {
  width: 18px;
  height: 18px;
  border: 2px solid rgba(0, 51, 74, 1);
  border-radius: 50%;
}

#menuRange .dropdown-item {
  text-align: center;
  color: rgba(0, 51, 74, 1);
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;
}

#menuRange .dropdown-item:hover {
  background: rgba(0, 51, 74, 1);
  color: white;
}

.filters .dropdown-item:hover {
  background: rgba(0, 51, 74, 1);
  color: white;
}

.filters .dropdown-item:hover .selectItem {
  background: white;
}

.filters .dropdown-item:hover .selectItem#active {
  background: white;
}

.filters .selectItem#active {
  background: rgba(0, 51, 74, 1);
}

.filters .dropdown-item.active,
.dropdown-item:active {
  background-color: white;
  color: black;
}

#iloscMenu input {
  max-width: 170px;
  border: none;
  padding: 5px 2px;
  margin: 4px 0;
  border-bottom: 1px solid #1e1e1e;
  color: var(--gray, #797979);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 19.2px */
}

#cenaMenu input {
  max-width: 70px;
}

#homeSearch {
  position: relative;
}

#homeSearch form {
  z-index: 3001;
  position: relative;
}

#results-box {
  box-shadow: 0px 2px 8px 0px #00000040;
  width: calc(100% - 1.5rem);
  position: absolute;
  background-color: #fff;
  z-index: 3000;
  top: 15px;
  padding: 0;
}

.liveSearchItem:first-child {
  margin-top: 40px;
  position: relative;
  z-index: 9999;
}

.liveSearchItem .rightDesc h5 {
  overflow: hidden;
  text-overflow: ellipsis;
}

.resultsSearch .liveSearchItem:first-child {
  margin-top: 35px;
}

.liveSearchItem .rightDesc .leftPrice {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding-left: 0;
  padding-right: 0;
}

.liveSearchItem .rightDesc .leftPrice .price_for {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 11px;
  line-height: 21px;
  color: #00334a;
  gap: 10px;
}

#results-box .emptyValue {
  margin-top: 45px;
  font-size: 18px;
  font-weight: 600;
  padding: 0 20px 10px;
}

.liveSearchPrice {
  background-color: #45cc24;
  display: inline;
  text-align: center;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
}

.liveSearchLocalization {
  color: #00334a;
  display: flex;
  align-items: end;
  justify-content: end;
  font-size: 16px;
  margin-right: 20px;
}

.liveSearchItem {
  display: flex;
  cursor: pointer;
  flex-direction: row;
  gap: 20px;
  padding: 15px;
  align-items: center;
  background: #fff;
}

.liveSearchItem:hover {
  background: #0000000d;
}

.liveSearchItem .leftImage {
  width: 100px;
}

.liveSearchItem .rightDesc {
  width: calc(100% - 120px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
}

.liveSearchItem .rightDesc .liveSearchPrice {
  padding: 3px 6px 3px 6px;
  color: #212529;
}

.liveSearchItem .offerTitle {
  height: 38px;
}

.liveSearchItem .rightDesc .d-flex {
  gap: 10px;
}

.liveSearchItem img {
  object-fit: cover;
}

.liveSearchItem .rightDesc .category {
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  text-align: left;
  color: #00334a;
}

.liveSearchItem .bottomFlex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.errorPage {
  background: #00334a;
  min-height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.errorPage .centeredText {
  text-align: left;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 2;
}

.errorPage .centeredText h1 {
  font-size: 200px;
  margin-bottom: 0;
  font-weight: 700;
  line-height: 90%;
  text-align: center;
  color: #ffffff;
}

.errorPage .centeredText h2 {
  margin-bottom: 0;
  font-size: 26px;
  font-weight: 700;
  line-height: 31.2px;
  text-align: left;
  color: white;
}

.errorPage .centeredText button {
  border-radius: 26px;
  box-shadow: 0px 0px 4px 0px #00000026;
  background: #45cc24;
  border: none;
  color: white;
  font-size: 16px;
  font-weight: 700;
  width: 160px;
  max-width: 60vw;
  height: 30px;
  line-height: 21.17px;
  text-align: center;
  margin: 20px 0;
}

.actionButtons {
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
  justify-content: center;
}
.actionButtons button {
  padding: 8px 28px;
  text-align: center;
}

.actionButtons .end {
  border-radius: 80px;
  background: var(--green, #45cc24);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
  border: 1px solid #45cc24;
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}

.errorPage .centeredText img.logo {
  width: 230px;
  max-width: 40vw;
}

.errorPage .background404 {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 35vw;
  height: auto;
  z-index: 1;
}

/* 100% Image Width on Smaller Screens */
@media only screen and (max-width: 700px) {
  .OfferDetailModal .modal-content {
    width: 100%;
  }
}

.myfade {
  animation-name: myfade;
  animation-duration: 1.5s;
}
.overflow-x-auto {
  overflow-x: auto;
}
.carousel-container {
  position: relative;
}
.pRelative {
  position: relative;
}
.carouselItemProduct {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  font-weight: bold;
  font-size: 20px;
  color: black;
  text-decoration: none;
}
.relatedOffersLink {
  display: flex !important;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  font-weight: bold;
  font-size: 20px;
  color: black;
  text-decoration: none;
}

/* 500 */

#UBackground {
  min-height: 100vh;
  width: 100vw;
  background: #00334a;
}

#UBackground .container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  align-items: baseline;
  justify-content: center;
  padding-top: 10vh;
  color: white;
  width: 100%;
}

#UBackground .container h1 {
  font-weight: 900;
  font-size: 68px;
  font-family: "Red Hat Display", sans-serif;
}

#UBackground .container p {
  font-family: Red Hat Display, sans-serif;
  font-size: 26px;
  font-weight: 700;
  line-height: 31px;
  letter-spacing: 0em;
  text-align: left;
}

#UBackground .container svg {
  margin: 30px 0;
}

#UBackground .bottom {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

#UBackground .bottom a {
  text-decoration: none;
}

#UBackground .bottomIMAGE {
  position: absolute;
  bottom: 0;
  right: 0;
}

/* ALERTS */

.alertImage {
  cursor: pointer;
  max-height: 40px;
}

#alertModal .closeModalOffers {
  position: absolute;
  cursor: pointer;
  top: 20px;
  right: 20px;
  color: #33363f;
  font-size: 24px;
  border: 2px solid #33363f;
  border-radius: 50%;
}

#alertModal h5 {
  font-size: 26px;
  font-weight: 700;
  line-height: 31.2px;
  text-align: center;
  color: #1e1e1e;
}

#alertModal .text {
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  text-align: left;
}

#alertModal button.add {
  background: #45cc24;
  border: 1px solid #45cc24;
  box-shadow: 0px 4px 4px 0px #0000001a;
  padding: 8px 28px 8px 28px;
  border-radius: 80px;
  color: white;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  width: fit-content;
}

#alertModal button.edit {
  border: 1px solid #45cc24;
  border-radius: 80px;
  box-shadow: 0px 4px 4px 0px #0000001a;
  padding: 8px 28px 8px 28px;
  background: transparent;
  width: fit-content;
  color: #45cc24;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
}

.alertModalButtons {
  margin-top: 20px;
  align-items: center;
}

.countOffers {
  font-size: 18px;
  font-weight: 400;
  line-height: 20px;
}

.modalImagesBig .arrow {
  position: absolute;
  top: 50vh;
  font-size: 30px;
  color: white !important;
  cursor: pointer;
  z-index: 999999;
}

.modalImagesBig .arrow.prev {
  left: 5% !important;
}
.modalImagesBig .arrow.next {
  right: 5% !important;
}

.fullScreenLoader .backdropLoader {
  background: #3d3d3d8f;
  position: fixed;
  width: 100vw;
  height: 100vh;
}

.fullScreenLoader {
  position: fixed;
  z-index: 999999;
  height: 100dvh;
  left: 0;
  top: 0;
  width: 100dvw;
}

.fullScreenLoader .UzyjtoLoader {
  position: absolute;
  z-index: 9999999;
}
@media only screen and (max-width: 600px) {
  #UBackground .container h1 {
    font-weight: 900;
    font-size: 30px;
    font-family: "Red Hat Display", sans-serif;
  }

  #UBackground .container p {
    font-family: Red Hat Display, sans-serif;
    font-size: 18px;
    font-weight: 700;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left;
  }

  #UBackground .bottomIMAGE {
    width: 100px;
  }
}

@keyframes zoom {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

@keyframes myfade {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}

/* BREAKPOINTS */
@media (max-width: 1400px) {
  #offerDetail .carousel a.infinite-item {
    width: 226px;
  }
}

@media only screen and (max-width: 1199px) {
  #offerDetail .carousel a.infinite-item {
    width: 280px;
  }
  .categoriesRowHome .imgWrapCat {
    width: 65px;
    height: 65px;
  }

  .categoriesRow .category {
    padding: 0;
  }

  .categoriesRow .category p {
    margin-bottom: 0;
  }

  #homeHero h1 {
    font-size: 48px;
    line-height: 110%;
  }

  #homeHero h2 {
    font-size: 22px;
  }

  .offersGrid {
    grid-template-columns: repeat(3, 1fr);
  }

  .headerSearch .contentInput {
    width: calc(65% - 110px);
  }

  .headerSearch .distanceGroup {
    width: 18%;
  }

  .headerSearch .cityInput {
    width: 27%;
  }
}

@media only screen and (max-width: 991px) {
  .categoriesRow {
    overflow-x: auto;
  }
  #offerDetail .carousel a.infinite-item {
    width: 310px;
  }

  .categoriesRowHome {
    display: none;
  }

  #homeMobileCategories {
    display: flex !important;
  }

  .topSearch .contentInput {
    width: 50%;
  }

  .topSearch .searchButton {
    width: 120px;
    font-size: 18px;
  }

  .topSearch .cityInput {
    width: calc(50% - 120px);
  }

  .offersGrid {
    grid-template-columns: repeat(2, 1fr);
  }

  .menuDeskop {
    gap: 20px;
  }

  .categoriesRow .category {
    flex-basis: 15%;
  }

  .addOfferButton {
    padding: 12px 22px;
  }

  .headerSearch .searchButton {
    padding: 0 20px;
  }

  .headerSearch .distanceGroup {
    width: 23%;
  }

  #homeHero h1 {
    font-size: 38px;
  }

  #homeHero h2 {
    font-size: 17px;
  }
}

.topZalogujSie {
  display: none;
}

@media only screen and (max-width: 767px) {
  .liveSearchLocalization {
    font-size: 11px;
    margin-right: 5px;
    text-align: right;
  }

  .liveSearchItem .bottomFlex {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: start;
    gap: 5px;
  }

  .offerTitle {
    height: auto;
  }
  .filters {
    gap: 5px;
  }
  #offerDetail h1.offerTitle {
    font-size: 24px;
  }
  .filters .filter {
    padding: 2px 4px;
  }
  .filters .filter button,
  .filters .filter.clear {
    font-size: 15px;
  }
  .topSearch .cityInput,
  .headerSearch .cityInput {
    border-radius: 0 !important;
  }
  .liveSearchItem .offerTitle {
    height: 34px !important;
  }

  .offer .leftSide .price_for {
    line-height: 120%;
  }

  .offer img {
    height: 190px;
  }

  .bottomOffer .row {
    align-items: end;
  }

  .offer .rightSide {
    line-height: 120%;
  }
  .alertImage {
    cursor: pointer;
    max-height: 30px;
  }

  @supports (padding-top: env(safe-area-inset-bottom)) {
    .buttonAddOffer {
      padding-bottom: env(safe-area-inset-bottom);
    }
  }
  .misjaTop h1,
  .bottomMisja h3,
  .misjaMiddle h2,
  .bottomMisja h4 {
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 1.5rem;
  }

  .misjaTop p,
  .misjaMiddle p,
  .bottomMisja p {
    font-size: 16px;
    line-height: 22px;
    text-align: justify;
  }

  #homePage {
    background: url("/public/images/mobileHero.png") no-repeat top right;
    background-size: 70%;
    position: relative;
  }

  #category {
    position: relative;
  }

  #category .carousel-container {
    margin-top: 5px;
  }

  #offers h1 {
    color: #000;
    text-align: left;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 19.2px */
  }

  body .carousel-container .categoriesCarousel {
    padding: 5px 0;
  }

  .buttonAddOffer {
    position: fixed;
    bottom: 20px;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    max-width: 90vw;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    left: 0;
    right: 0;
  }

  .buttonAddOffer button {
    border-radius: 60px;
    background: #fff;
    box-shadow: 0px 0px 9px 3px rgba(0, 0, 0, 0.15);
    padding: 10px 20px;
    color: var(--navy, #00334a);
    font-family: Red Hat Display;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border: none;
  }

  .only_mobile {
    display: block;
  }

  .no_mobile {
    display: none !important;
  }

  #footer a {
    text-align: left;
  }

  .menuMobile {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  #offerDetail .offerTitle {
    font-size: 24px;
  }

  .headerSearch input {
    padding-left: 5px;
  }

  .headerSearch .myInput input {
    width: 70px;
  }

  #offerDetail .prev::after,
  #offerDetail .next::after {
    height: 2em;
    width: 2em;
  }

  .headerSearch input {
    font-size: 18px;
  }

  .menuDeskop {
    display: none;
  }

  .headerSearch {
    flex-wrap: wrap;
    border-radius: 10px;
  }

  .headerSearch .contentInput {
    width: 50%;
    font-size: 16px;
  }

  .headerSearch .distanceGroup {
    display: none;
  }

  #homePage #homeSearch {
    margin-top: 2rem;
  }

  .headerSearch {
    height: fit-content;
  }

  .headerSearch .cityInput {
    border-left: 1px solid white;
    width: 30%;
    padding-left: 10px;
    font-size: 16px;
  }

  .headerSearch .searchButton {
    width: 20%;
    border-radius: 10px;
    font-size: 14px;
    padding: 5px 10px;
  }

  .categoriesRow .category {
    flex-basis: 20%;
  }

  .categoriesRow {
    justify-content: center;
    gap: 5px;
  }

  .topSearch {
    height: 50px;
  }

  .topSearch input {
    font-size: 16px;
  }

  .topSearch .searchButton {
    font-size: 16px;
    width: 100px;
  }

  .topSearch .cityInput {
    width: calc(50% - 100px);
  }

  .offer .leftSide {
    font-size: 11px;
  }

  .offer .rightSide {
    font-size: 11px;
  }
}

@media only screen and (max-width: 575px) {
  .errorPage .centeredText h1 {
    font-size: 140px;
  }

  .errorPage .centeredText h2 {
    font-size: 18px;
  }

  .errorPage .background404 {
    width: 50vw;
  }

  #offerDetail .offerSlide {
    height: 55vh;
  }

  #category .imgWrapCat {
    padding: 0px;
  }

  #category .imgWrapCat img {
    padding: 15px;
  }

  #category #subCategory .imgWrapCat {
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  #category #finalCategory .imgWrapCat {
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  #subCategory .categoryBox,
  #finalCategory .categoryBox {
    width: 70px;
  }

  #subCategory .categoryBox p {
    font-size: 0.7rem;
  }

  #homeHero h1 {
    font-size: 28px;
  }

  #homeHero h2 {
    font-size: 16px;
  }

  .topSearch input {
    padding-left: 10px;
    font-size: 15px;
  }

  .topSearch .searchButton {
    font-size: 14px;
    width: 70px;
  }

  .topSearch .cityInput {
    width: calc(50% - 70px);
  }

  .offersGrid {
    grid-template-columns: repeat(1, 1fr);
  }

  .footerColumn {
    text-align: center;
  }

  #footerLogo {
    margin-top: 20px;
    justify-content: center;
  }

  .liveSearchItem {
    align-items: center;
  }

  .liveSearchItem .offerTitle {
    font-size: 14px;
    line-height: 120%;
  }

  .liveSearchItem .rightDesc .liveSearchPrice {
    font-size: 14px;
    padding: 2px 4px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1040px !important;
  }
}

@media (min-width: 1400px) {
  .container {
    max-width: 1000px !important;
  }
}

@media screen and (max-width: 385px) {
  .topSearch .cityInput {
    border-radius: 0;
  }

  .headerLogo {
    max-height: 45px;
  }

  .topMobileMenu {
    margin-right: 18px;
  }
}

@media screen and (max-width: 360px) {
  .headerLogo {
    max-height: 42px;
  }

  .topMobileMenu {
    margin-right: 16px;
  }
}
